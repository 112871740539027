import React from 'react'
import Link from 'gatsby-link'
import { Divider} from 'semantic-ui-react'
import P5Component from '../components/P5Component'
// import { sketchData } from '../sketchData'
import sketch1 from "../sketches/01.js"
import domainMap from '../assets/images/death-domain-map.png'
import circle2 from '../assets/images/circle-2.png'
import woman from '../assets/images/woman.png'
import mastImg from './../assets/images/skull.png'
import coverImg from './../assets/images/cover-img-2.png'
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import 'semantic-ui-css/semantic.min.css';
import './style.css'

const SecondPage = () => (
   <div className="letter-editor"> 
         {/* <div className="article-back-absolute"><Link className="white" to='/table-of-contents'>&larr;</Link></div> */}
    <img className="p2-cover-lines" src={coverImg} />    
        <div className="p2-left-side">
         <img className="p2-circle" src={circle2} />
           <div className="quote-wrapper">
            <Divider horizontal><div className="special-divide-text">From Here to Eternity<span className="extra-text">: Traveling the World to Find the Good Death</span></div></Divider>
            <blockquote className="home-quote">
                "If we can be called best at anything it would be at keeping our grieving families separated from their dead."
            </blockquote>
            <Divider horizontal><div className="special-divide-text">Caitlin Doughty, Undertaking LA</div></Divider>
           </div>


           <div className="trend-wrapper">
                        <div className="carbon-header">US Death Stats:</div>
                        <ul>
                            <li>US citizens pay on average over $8,000 per funeral. Funeral costs could be less than $1500.</li>
                            <li>Only 2 in 10 people between the ages of 18-36 have a will</li>
                            <li>Only 3 in 10 people between the ages of 37-52 have a will</li>
                            <li>Only 42% of Americans have a will</li>
                            <li>By 2035 less than 25% of the population will choose to be buried.</li>
                            <li>We spend billions of dollars in excess on healthcare because people haven't documented end of life care decisions, like their Do Not Resuscicate status. Estimates range from 15-30% of total healthcare is spent on care for the terminally ill.</li>
                        </ul>
            </div>
           <div className="page-2-next-wrapper"> 
           <img className="woman" src={woman} />
           <h2>NEXT STOP:</h2>
                <ul>
                    <li><Link to="/research">Research</Link></li>
                    {/* <li><Link to="/scenarios">Scenarios</Link></li> */}
                    <li><Link to="/table-of-contents">Home</Link></li>
                </ul>
            </div>
           
           
          
                    {/* <img className="woman" src={woman} /> */}
        </div>

        <div className="letter-content">
        <img className="letter-mast" src={mastImg} />
            <p>
                <span className="first-character">L</span>et’s start with a question, “Are you prepared for your own death?” And I don’t mean that in the gut-wrenching, come to terms with your life kind of way, just pragmatically, are you prepared? Buried or cremated? If buried, where? What kind of casket? What kind of obituary? If you are a burial person, what's on your headstone? Green or embalmed? If cremated, do you want your ashes spread on a mountain, in a river, or maybe thrown across Wrigley Field? What do you know about the future of death tech --- mushroom suits, promession, human composting, AI avatars, downloading consciousness? How do you feel about Death or Life after Death as a service? Do you even have a will? Most of us definitely do not. What if you are taken into the E.R., do you want to be resuscitated? What if you had to decide between a 95% chance that you will die painfully and dramatically on the operating table versus being given comfort care and saying goodbye to your loved ones?  Maybe you want to be cryogenically frozen (for now I’d pass), or uploaded to the internet for life on the cloud(not quite there yet, but we're working on it). Have you thought about any of this? Because, I’m 42, and most of it has barely even occurred to me. 
            </p>
            <p>
            The truth is the number of decisions that have to be made when someone dies are staggering and we haven’t even started asking the gut-wrenching questions yet. Have you come to terms with your life? Have you accepted your own fate? What about your loved ones? Do they know what you want? If they had to say goodbye would they be able to let you go peacefully, or would they unknowingly put you through the worst hell imaginable as your last experience? Do you know how you will be remembered? Do you care?
            </p>
            
    
            <p>
                In other parts of the world death is kept close, shockingly so in some places, and isn’t nearly as terrifying. In the United States death has been hidden from us, by our religion, by our laws, and by a historically relentless death industry that has capitalized on grief in order to charge exorbitant prices for often unneeded or unwanted services.

            </p>
            <p>
                As we enter a new era in our society, one where what it means to be human is going to be reexamined we have an opportunity to rethink how we die and how we live on after we die. 
            </p>
            <p>This website is an exploratino of the future of death in America. We will explore what is driving it and ultimately the outer limits of what it may become. My hope is that by talking to people who live with death everyday, by researching the industry of death, and by imagining some of the ways our future may look, we will be better prepared to influence how we may experience death one day.</p>
            {/* <Link to="/page-2/">Go to page 2</Link> */}
            
        </div>
  </div>
)

export default SecondPage
