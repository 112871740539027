import React from "react"

class P5Component extends React.Component {

	componentDidMount() {
		const p5 = require('p5')
    	this.canvas = new p5(this.props.sketch, this.wrapper);
       	if( this.canvas.myCustomRedrawAccordingToNewPropsHandler ) {
         this.canvas.myCustomRedrawAccordingToNewPropsHandler(this.props);
       }
	}

	componentWillUnmount() {
		if (this.props.sketchMount == false) {
			console.log('unmounting')
		   this.wrapper.removeChild(this.wrapper.childNodes[0]);
		   this.canvas = ""
		}
    // if(this.props.sketch !== newprops.sketch){
    //   this.wrapper.removeChild(this.wrapper.childNodes[0]);
    //   this.canvas = new p5(newprops.sketch, this.wrapper);
    // }
    if( this.canvas.myCustomRedrawAccordingToNewPropsHandler ) {
      // this.canvas.myCustomRedrawAccordingToNewPropsHandler(newprops);
    }


	}

	render() {
		return <div ref={wrapper => this.wrapper = wrapper}></div>;
}

}

export default P5Component
